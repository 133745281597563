<template>
  <layout-center>
    <form>
      <v-card v-if="item">
        <v-card-title>
          <div class="title">
            {{ $lang(item.id ? "UPDATE" : "NEW") + " " + $lang("QUOTE") }}
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-img
            height="100"
            :src="item.quote_image"
            @click="handleShowUploader"
          >
            <v-btn fab v-if="!showUploader" @click="handleShowUploader">
              <v-icon>mdi-image</v-icon>
            </v-btn>
          </v-img>

          <upload-Area
            v-if="showUploader"
            inputIdent="upload-input-quote-image"
            :show="showUploader"
            identity="quotes/image/"
            @uploaded="handleUploaded"
          />
        </v-card-text>
        <v-card-text>
          <vue-tip
            variant="minimal"
            :initial="item.quote"
            @changed="
              (val) => {
                item.quote = val;
              }
            "
            placeholder="Quote"
          />
          <v-text-field
            label="Author"
            v-model="item.author"
            type="text"
            :error-messages="errors && errors.author ? errors.author : []"
          ></v-text-field>

          <vue-tip
            variant="minimal"
            :initial="item.quote_hi"
            @changed="
              (val) => {
                item.quote_hi = val;
              }
            "
            placeholder="कथन"
          />

          <v-text-field
            label="लेखक"
            v-model="item.author_hi"
            type="text"
            :error-messages="errors && errors.title_hi ? errors.title_hi : []"
          ></v-text-field>
          <selector-language
            :select="item.language"
            @selected="(abbr) => (item.language = abbr)"
          />
        </v-card-text>

        <v-card-actions>
          <div class="text-xs-center">
            <v-btn outlined @click="doSubmit">{{
              $lang(item.id ? "UPDATE" : "CREATE")
            }}</v-btn>
            <v-btn text @click="$router.push({ name: 'dashboard-quotes' })">{{
              $lang("CANCEL")
            }}</v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="item.id"
            color="red"
            outlined
            @click="handleDelete(item)"
          >
            <v-icon>mdi-delete</v-icon> {{ $lang("REMOVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import { axios } from "@/plugins/axios";
import SelectorLanguage from "@/app/dashboard/prep/modules/SelectorLanguage";
import VueTip from "@/components/VueTip";
import UploadArea from "@/app/dashboard/auth/modules/UploadArea.vue";

export default {
  name: "dashboard-quotes-create",
  components: {
    LayoutCenter,
    VueTip,
    SelectorLanguage,
    UploadArea
  },
  data() {
    return {
      item: {
        id: null,
      },
      loading: false,
      showUploader: false,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    handleShowUploader() {
      this.showUploader = true;
    },
    handleUploaded(url) {
      this.item.quote_image = url;
      this.showUploader = false;
    },
    handleDelete(item) {
      if (
        !confirm(`Are you sure to delete ${item.quote ? item.quote : item.id}?`)
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }

      bus.$emit("showWait");
      this.loading = true;
      return axios
        .delete(`/quotes/${item.id}`)
        .then((res) => {
          this.items = this.items.filter((el) => el.id != item.id);
          return Promise.resolve(res);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    fetchItem(id) {
      return axios
        .get(`quotes/${id}`)
        .then((res) => {
          this.item = res.data;
        })
        .catch(() => {
          this.$router.replace({ name: "dashboard-quotes" });
        });
    },
    doSubmit() {
      if (this.$route.params.id) {
        return axios
          .post(`quotes/${this.$route.params.id}`, this.item)
          .then((res) => {
            // this.item = res.data;
            // this.$router.replace({
            //   name: "dashboard-quotes-create",
            //   params: { id: this.$route.params.id },
            // });
            this.$router.replace({ name: "dashboard-quotes" });
          });
      } else {
        return axios
          .post(`quotes`, this.item)
          .then((res) => {
            this.$router.replace({
              name: "dashboard-quotes-create",
              params: { id: res.data.data.id },
            });
          })
          .catch(() => {
            // this.$router.replace({ name: "dashboard-quotes" });
          });
      }
    },
  },
  watch: {
    "$route.params.id"(id) {
      this.fetchItem(id);
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }
  },
};
</script>
